import React from 'react';
import { Row, Form } from 'react-bootstrap';

const CheckboxList = ({ children }) => {
  return (
    <Row className="w-100">
      <Form className="pl-3 pb-3">{children}</Form>
    </Row>
  );
};

export default CheckboxList;
