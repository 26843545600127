import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import './index.css';
import ImportUsers from './ImportUsers';
import CreateUsers from './CreateUsers';
import translations from '../../../translations/content';

const Import = () => {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <Col>
            <h1 className="h3 mt-4">{translations.dashboard}</h1>
            <p className="mt-4 ml-2">{translations.groupMessage}</p>
            <ImportUsers />
            <Row>
              <CreateUsers />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Import;
