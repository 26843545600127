import React from 'react';

import './index.css';
import LessonsListTooltip from '../LessonsListTooltip';
import flattenSingleLevelArray from '../../../utils/flattenSingleLevelArray';

const GroupTableElement = ({
  course: { finished, started, percentage, lessons },
  allCourses,
  courseName,
}) => {
  const allLessonsPerCourse = allCourses.map(course => course.lessons);
  const allLessons = flattenSingleLevelArray(allLessonsPerCourse);
  const lessonsWithName = { ...lessons };
  Object.keys(lessons).forEach(id => {
    const aLesson = allLessons.filter(lesson => String(lesson.id) === id);
    lessonsWithName[id].name = aLesson[0].name;
  });
  let className = '';
  if (finished) {
    className = 'group-table-element-finished bg-success my-auto mx-auto';
  } else if (started) {
    className = 'group-table-element-started bg-warning';
  }
  return (
    <>
      {finished || started ? (
        <LessonsListTooltip
          lessons={lessonsWithName}
          courseName={courseName}
          placement="top"
        >
          <div className={className}>{percentage || ''}</div>
        </LessonsListTooltip>
      ) : (
        <div data-testid="empty-div" className={className} />
      )}
    </>
  );
};

export default GroupTableElement;
