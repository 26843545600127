import React from 'react';
import { Button, Image } from 'react-bootstrap';

import translations from '../../../../../translations/importUsersModal';
import email from '../../../../../assets/email.png';

const EmailListSelectionModalBody = ({ showMessage, onClick }) => {
  if (showMessage) {
    return <></>;
  }

  return (
    <Button
      variant="google-classroom"
      type="submit"
      className="d-flex align-items-center mb-3 p-0"
      onClick={onClick}
      data-testid="google-classroom-selection-modal-body-import-button"
    >
      <Image
        src={email}
        data-testid="google-classroom-selection-modal-body-image"
        className="google-classroom-selection-modal-body-button-google-classroom-logo"
      />
      <span className="pr-2">{translations.emailList}</span>
    </Button>
  );
};

export default EmailListSelectionModalBody;
