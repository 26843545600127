import createObjectFromEntries from './createObjectFromEntries';

export const getNotysingUsers = groupData => {
  const { userAliases, users } = groupData;
  return users.map(user => {
    return {
      ...user.notysing,
      id: user.id,
      alias: userAliases.find(alias => alias.userId === user.id).alias,
    };
  });
};

export const getScoreAllExercises = (user, courseTree) => {
  const reshapedUsers = {};
  const lessons = courseTree.flatMap(course => course.lessons);
  const exercises = lessons.flatMap(lesson => lesson.exercises);
  const getLesson = id => lessons.find(lesson => lesson.id === id);
  const getExercise = id => exercises.find(exercise => exercise.id === id);

  let userLessons = [];
  if (user && user.lessons && user.lessons.length) {
    userLessons = user.lessons.map(lesson => {
      return {
        ...lesson,
        type: `Lesson ${lesson.id.toString().slice(-1)}`,
        lesson: getLesson(lesson.id) ? getLesson(lesson.id).name : '',
        exercise: '-',
      };
    });
  }

  let userExercises = [];
  if (user && user.exercises && user.exercises.length) {
    userExercises = user.exercises.map(exercise => {
      return {
        ...exercise,
        type: `Exercise ${exercise.id.toString().slice(-2)}`,
        lesson: '-',
        exercise: getExercise(exercise.id) ? getExercise(exercise.id).type : '',
      };
    });
  }

  const userScore = [...userLessons, ...userExercises];
  let userScoreWithKeys = [];
  if (userScore.length) {
    userScore.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    userScoreWithKeys = userScore.map(data => ({
      ...data,
      key: Math.random(),
    }));
  }

  const coursesIds = courseTree.map(course => course.id);
  const coursesEntries = coursesIds.map(id => {
    return [
      id,
      {
        finished: false,
        started: false,
        percentage: 0,
        lessons: {},
      },
    ];
  });
  const reshapedCourses = createObjectFromEntries(coursesEntries);
  courseTree.forEach(course => {
    const lessonIds = course.lessons.map(lesson => lesson.id);
    const userCourseLessonsEntries = lessonIds.map(id => {
      return [
        id,
        {
          finished: false,
          score: 0,
          maxScore: 0,
          percentage: 0,
        },
      ];
    });
    const userCourseLessons = createObjectFromEntries(userCourseLessonsEntries);

    if (user && user.lessons && user.lessons.length) {
      const userLessonsAll = user.lessons;
      userLessonsAll.forEach(userLesson => {
        if (lessonIds.includes(userLesson.id)) {
          if (userLesson.finished) {
            userCourseLessons[userLesson.id].finished = true;
            if (
              userLesson.score &&
              userLesson.score > userCourseLessons[userLesson.id].score
            ) {
              userCourseLessons[userLesson.id].score = userLesson.score;
              userCourseLessons[userLesson.id].maxScore = userLesson.maxScore;
              if (userLesson.maxScore) {
                userCourseLessons[userLesson.id].percentage = Math.round(
                  (userLesson.score / userLesson.maxScore) * 100
                );
              }
            }
          }
        }
      });
      reshapedCourses[course.id].lessons = userCourseLessons;
      reshapedCourses[course.id].finished =
        Object.values(reshapedCourses[course.id].lessons).filter(
          lesson => lesson.finished
        ).length === course.lessons.length;
      reshapedCourses[course.id].started =
        Object.values(reshapedCourses[course.id].lessons).some(
          lesson => lesson.finished === true
        ) && !reshapedCourses[course.id].finished;

      if (reshapedCourses[course.id].finished) {
        const courseLessonsPercentages = Object.values(
          reshapedCourses[course.id].lessons
        ).reduce(
          (a, { percentage }) => (percentage ? [...a, percentage] : a),
          []
        );
        reshapedCourses[course.id].percentage =
          courseLessonsPercentages.length > 0
            ? Math.round(
                courseLessonsPercentages.reduce((a, b) => a + b, 0) /
                  courseLessonsPercentages.length
              )
            : 0;
      }
    }
    // reshapedUsers[user.id] = {
    //   alias: user.alias,
    //   courses: reshapedCourses,
    // };
  });

  return userScoreWithKeys;
};
