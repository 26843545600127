import React from 'react';
import { Button, Image } from 'react-bootstrap';

import './index.css';
import translations from '../../../../../translations/importUsersModal';
import GoogleClassroomLogo from '../../../../../assets/32x32_yellow_stroke_icon@2x (1).png';

const GoogleClassroomSelectionModalBody = ({ showMessage, onClick }) => {
  if (showMessage) {
    return (
      <>
        <p>{translations.clickToOpen}</p>
      </>
    );
  }

  return (
    <Button
      variant="google-classroom"
      type="submit"
      className="d-flex align-items-center mb-3 p-0"
      onClick={onClick}
      data-testid="google-classroom-selection-modal-body-import-button"
    >
      <Image
        src={GoogleClassroomLogo}
        data-testid="google-classroom-selection-modal-body-image"
        className="google-classroom-selection-modal-body-button-google-classroom-logo"
      />
      <span className="pr-2">{translations.googleClassroom}</span>
    </Button>
  );
};

export default GoogleClassroomSelectionModalBody;
