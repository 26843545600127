/*
 * action types
 */

export const SET_ERROR_ALERT = 'SET_ERROR_ALERT';
export const HIDE_ERROR_ALERT = 'HIDE_ERROR_ALERT';

/*
 * action creators
 */

export const setErrorAlert = errorAlert => {
  return {
    type: SET_ERROR_ALERT,
    errorAlert,
  };
};

export const hideErrorAlert = () => {
  return {
    type: HIDE_ERROR_ALERT,
  };
};

/*
 * Reducer
 */

const initialState = null;

const errorAlertReducer = (state = initialState, action) => {
  const { errorAlert } = action;

  if (errorAlert) {
    return errorAlert;
  }
  if (action.type === HIDE_ERROR_ALERT) {
    return null;
  }

  return state;
};

export default errorAlertReducer;
