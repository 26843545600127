/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import { removeGroup, editGroup } from '../GroupsDropdown/duck';
import { getGroup } from './duck';
import { setError } from '../../ErrorNotification/duck';
import { http } from '../../../services/http';
import { addUsersToGroupURL } from '../../../utils/urls';
import translations from '../../../translations/groupContent';
import GroupNameModal from '../GroupNameModal';
import GroupTable from '../GroupTable';
import ConfirmationModal from '../ConfirmationModal';
import isCourseTreeEmpty from '../../../utils/isCourseTreeEmpty';
import { useAlert } from '../../../utils/showAlert';
import ErrorAlert from '../../ErrorAlert';
import { useCourseTree } from '../../../hooks/useCourseTree';
import LoadingSpinner from '../LoadingSpinner';
import { setSpinnerVisibility } from '../LoadingSpinner/duck';

const GroupContent = ({ gId }) => {
  const [values, setValues] = useState({
    uId: '',
    uAlias: '',
  });
  const [modalShow, setModalShow] = useState(false);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  // const [courseTree, setCourseTree] = useState([{}]);
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const courseTree = useCourseTree();

  const selectedGroup = useSelector(state =>
    state.groups.filter(group => group.id === gId)
  )[0] || { name: '' };

  useEffect(() => {
    if (!isCourseTreeEmpty(courseTree)) {
      dispatch(setSpinnerVisibility(true));
      dispatch(getGroup(gId, courseTree)).then(() =>
        dispatch(setSpinnerVisibility(false))
      );
    }
  }, [dispatch, gId, courseTree]);

  const handleEditIconClick = () => {
    setModalShow(true);
  };

  const handleSaveGroupName = name => {
    setModalShow(false);
    if (name.trim().length) {
      dispatch(editGroup(name, gId));
    }
  };

  const handleHideModal = () => {
    setModalShow(false);
  };

  const handleDeleteGroup = () => {
    setConfirmationModalShow(true);
  };

  const handleCancelDelete = () => {
    setConfirmationModalShow(false);
  };

  const handleConfirmDelete = () => {
    try {
      dispatch(removeGroup(gId));
      setConfirmationModalShow(false);
      history.push('/');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleChange = event => {
    const { id, value } = event.target;
    setValues(prevValues => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleSubmit = async () => {
    const { uId, uAlias } = values;
    const user = uId.includes('@')
      ? {
          provider: 'email',
          id: uId.toLowerCase().trim(),
          alias: uAlias,
        }
      : {
          id: uId.toLowerCase().trim(),
          alias: uAlias,
        };
    try {
      await http.patch(addUsersToGroupURL(gId), { users: [user] });
      setValues({ uId: '', uAlias: '' });
      dispatch(getGroup(gId, courseTree));
    } catch (e) {
      if (e.response.status === 400) {
        alert.error(translations.error, translations.invalidUser);
      } else {
        dispatch(setError(e));
      }
    }
  };

  return (
    <>
      <ErrorAlert />
      <Container className="group-content">
        <Row>
          <Col className="mt-4 group-content-name">
            <span className="h3 mt-4">{selectedGroup.name}</span>
            {selectedGroup.name ? (
              <FontAwesomeIcon
                icon={faPen}
                onClick={handleEditIconClick}
                className="group-content-icon"
                data-testid="update-icon"
              />
            ) : null}
            <LoadingSpinner />
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            <GroupTable gId={gId} courses={courseTree} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit} className="mt-4 group-content-form">
          <Form.Row>
            <Form.Group as={Col} controlId="uId">
              <Form.Control
                size="sm"
                className="group-content-form-control text-lowercase"
                type="text"
                placeholder="Email or User ID"
                onChange={handleChange}
                value={values.uId}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="uAlias">
              <Form.Control
                size="sm"
                className="group-content-form-control"
                type="text"
                placeholder="User display name"
                onChange={handleChange}
                value={values.uAlias}
              />
            </Form.Group>
            <Col>
              <Button
                size="sm"
                onClick={handleSubmit}
                disabled={!(values.uId && values.uAlias)}
              >
                {translations.addToGroup}
              </Button>
            </Col>
          </Form.Row>
        </Form>
        <Row className="mt-4">
          <Col>
            <Button variant="danger" size="sm" onClick={handleDeleteGroup}>
              {translations.deleteGroup}
            </Button>
          </Col>
        </Row>
      </Container>
      {modalShow ? (
        <GroupNameModal
          groupName={selectedGroup.name}
          show={modalShow}
          onHide={handleHideModal}
          onSave={handleSaveGroupName}
        />
      ) : null}
      {confirmationModalShow ? (
        <ConfirmationModal
          show={confirmationModalShow}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
    </>
  );
};

export default GroupContent;
