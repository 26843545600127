import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import translations from '../../../../../translations/importUsersModal';
import {
  setGroup,
  setNewGroup,
} from '../CourseAndGroupSelectFormModalBody/duck';

const GroupSelectForm = ({
  extraOptions = [],
  labelText,
  showInfoText = true,
}) => {
  const dispatch = useDispatch();
  const groups = useSelector(state => state.groups);
  const group = useSelector(state => state.courseAndGroupSelectReducer.group);
  const newGroup = useSelector(
    state => state.courseAndGroupSelectReducer.newGroup
  );
  const groupOptions = [...groups, ...extraOptions];

  useEffect(() => {
    if (groups.length) {
      dispatch(setGroup(groups[0].id));
    }
  }, [dispatch, groups]);

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === 'group' && value !== 'add') {
      dispatch(setGroup(value));
      dispatch(setNewGroup(''));
    } else if (name === 'group' && value === 'add') {
      dispatch(setGroup('add'));
    } else if (name === 'newGroup') {
      dispatch(setGroup('add'));
      dispatch(setNewGroup(value));
    }
  };

  return (
    <>
      <Form data-testid="form">
        <Form.Group>
          <Form.Label htmlFor="group-select">{labelText}</Form.Label>
          <Form.Control
            as="select"
            id="group-select"
            name="group"
            custom
            onChange={handleChange}
            value={group}
          >
            {groupOptions.map(({ id, name }) => {
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>

        {group === 'add' || group === '' ? (
          <Form.Group>
            <Form.Label htmlFor="create-group">
              {translations.newGroupName}
            </Form.Label>
            <Form.Control
              size="sm"
              className="choose-group-form-control-new-group"
              type="text"
              name="newGroup"
              placeholder="Create a group"
              id="create-group"
              onChange={handleChange}
              value={newGroup}
            />
          </Form.Group>
        ) : null}
      </Form>
      {showInfoText && <p>{translations.clickToAddUsers}</p>}
    </>
  );
};

export default GroupSelectForm;
