const translations = {
  resetPassword: 'RESET PASSWORD',
  setPassword: 'SET PASSWORD',
  backToLogin: 'Back to login',
  invalidPassword: 'Password is invalid.',
  passwordHasBeenReset: 'Password has been reset!',
  passwordHasBeenSet: 'Password has been set!',
  backToAccount: 'Back to account page',
};

export default translations;
