import validateEmail from './validateEmail';
import translations from '../translations/validateForm';
import validatePassword from './validatePassword';

const validateForm = values => {
  const { email, password, verifyPassword } = values;
  const errors = {};

  if (email && !validateEmail(email)) {
    errors.email = translations.invalidEmail;
  }

  if (password) {
    if (!validatePassword(password)) {
      errors.password = translations.invalidPassword;
    } else if (verifyPassword && verifyPassword !== password) {
      errors.verifyPassword = translations.passwordsDoNotMatch;
    }
  }

  if (verifyPassword) {
    if (!validatePassword(verifyPassword)) {
      errors.verifyPassword = translations.invalidPassword;
    } else if (verifyPassword !== password) {
      errors.verifyPassword = translations.passwordsDoNotMatch;
    }
  }

  return errors;
};

export default validateForm;
