import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import translations from '../../translations/verifyEmail';
import { http } from '../../services/http';
import { verifyEmailURL } from '../../utils/urls';

const VerifyEmail = () => {
  const [error, setError] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await http.get(verifyEmailURL(token));
      } catch (e) {
        setError(true);
      }
    };
    verifyEmail();
  }, [token]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="overflow-hidden border-2 my-5">
            <Card.Body className="p-0">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div className="p-5">
                    <Card.Title className="text-center">
                      {translations.emailVerification}
                    </Card.Title>
                    {error ? (
                      <Alert variant="danger" className="mt-5 mb-5">
                        <p>{translations.errorMessage}</p>
                      </Alert>
                    ) : (
                      <Alert variant="success" className="mt-5 mb-5">
                        <p>{translations.successMessage}</p>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default VerifyEmail;
