import React, { useState } from 'react';
import { Form, Card, Image } from 'react-bootstrap';

import './index.css';

const StudentsSelectModalBody = ({ onChange, students, values }) => {
  const [hiddenImages, setHiddenImages] = useState(
    students.reduce(
      (ac, cv) => ({
        ...ac,
        [cv.id]: true,
      }),
      {}
    )
  );
  const { checkedStudentIds } = values;

  const handleImageLinkSuccessfulLoad = event => {
    const { id } = event.target;
    setHiddenImages(prevState => ({
      ...prevState,
      [id]: false,
    }));
  };

  return (
    <>
      <Card>
        <Card.Body className="students-select-modal-body-card-body">
          {students.map(({ id, name, photoUrl }) => {
            return (
              <Form.Check
                key={id}
                type="checkbox"
                className="students-select-modal-body-form-check"
              >
                <Form.Check.Input
                  type="checkbox"
                  data-testid={`checkbox${id}`}
                  id={id}
                  checked={checkedStudentIds[id]}
                  onChange={onChange}
                  className="h-100 students-select-modal-body-form-check-input"
                />
                <Form.Check.Label className="h-100 students-select-modal-body-form-check-label">
                  <span
                    title={name}
                    className="students-select-modal-body-form-check-label-name"
                  >
                    {name}
                  </span>
                  <Image
                    src={photoUrl}
                    id={id}
                    onLoad={handleImageLinkSuccessfulLoad}
                    data-testid={photoUrl}
                    className={`ml-2 students-select-modal-body-form-check-label-image ${
                      hiddenImages[id]
                        ? 'students-select-modal-body-form-check-label-image-hidden'
                        : ''
                    }`}
                  />
                </Form.Check.Label>
              </Form.Check>
            );
          })}
        </Card.Body>
      </Card>
    </>
  );
};

export default StudentsSelectModalBody;
