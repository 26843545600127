import React, { useState, useEffect } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { hideErrorAlert } from './duck';

const ErrorAlert = () => {
  const errorAlert = useSelector(state => state.errorAlert);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(errorAlert ? errorAlert.title : '');
    setMessage(errorAlert ? errorAlert.message : '');
  }, [errorAlert]);

  const handleHide = () => {
    dispatch(hideErrorAlert());
  };

  return (
    <Modal
      show={!!title}
      onHide={handleHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton id="contained-modal-title-vcenter">
        <h5 className="font-weight-bold">{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <Alert show variant="danger">
          <p>{message}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={handleHide} variant="outline-secondary">
              OK
            </Button>
          </div>
        </Alert>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorAlert;
