import React from 'react';
import { useLocation, useHistory } from 'react-router';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faRedoAlt from '@fortawesome/fontawesome-free-solid/faRedoAlt';

import { http } from '../../../services/http';
import { getCurrentUserURL } from '../../../utils/urls';

const PermanentNotification = ({ message, buttonText, onClick }) => {
  const location = useLocation();
  const history = useHistory();

  const fetchCurrentUser = async () => {
    try {
      const { data: user } = await http.get(getCurrentUserURL());
      localStorage.setItem('user', JSON.stringify(user));
      history.push(location.pathname);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  return (
    <>
      <div className="pink-stripe">
        <div className="pink-stripe-msg">{message}</div>
        <button
          type="button"
          className="pink-btn m-right-1 m-left-1"
          onClick={onClick}
        >
          {buttonText}
        </button>
        <div className="refresh-btn-container">
          <FontAwesomeIcon
            icon={faRedoAlt}
            className="refresh-btn"
            onClick={fetchCurrentUser}
          />
        </div>
      </div>
    </>
  );
};

export default PermanentNotification;
