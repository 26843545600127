import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { http } from '../../../services/http';
import { setError } from '../../ErrorNotification/duck';
import { addUsersToGroupURL } from '../../../utils/urls';
import GroupSelectFormModalBody from '../HomeScreen/ImportUsers/GroupSelectFormModalBody';
import { createGroup } from '../GroupsDropdown/duck';
import { useAlert } from '../../../utils/showAlert';
import SuccessAlert from '../../SuccessAlert';
import InfoAlert from '../../InfoAlert';
import ErrorAlert from '../../ErrorAlert';
import translations from '../../../translations/createdStudentsList';
import courseAndGroupSelectFormModalBodyConstants from '../../../constants/courseAndGroupSelectFormModalBody';

const AddToGroupModal = ({ checkedStudents, visibility, onHide }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const groups = useSelector(state => state.groups);
  const group = useSelector(state => state.courseAndGroupSelectReducer.group);
  const newGroup = useSelector(
    state => state.courseAndGroupSelectReducer.newGroup
  );

  const addToGroup = async () => {
    let id = '';
    if (newGroup) {
      id = groups.filter(gr => gr.name === newGroup)[0].id;
    } else {
      id = group;
    }
    const addToGroupUsers = checkedStudents.map(user => {
      return {
        id: user.username,
        alias: user.username,
        provider: 'username',
      };
    });
    try {
      const addToGroupResponse = await http.patch(addUsersToGroupURL(id), {
        users: addToGroupUsers,
      });
      if (addToGroupResponse.status === 207) {
        onHide();
        alert.info(translations.partialSuccess, translations.addedPartialy);
      }
      if (addToGroupResponse.status === 200) {
        onHide();
        alert.success(
          translations.usersAdded,
          translations.usersAddedSuccessfully
        );
      }
    } catch (e) {
      onHide();
      dispatch(setError(e));
    }
  };

  useEffect(() => {
    if (
      checkedStudents.length &&
      newGroup &&
      groups.find(gr => gr.name === newGroup)
    ) {
      addToGroup();
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  const handleChooseGroup = () => {
    if (group === 'add') {
      dispatch(createGroup(newGroup));
    } else {
      addToGroup();
    }
  };

  return (
    <>
      <SuccessAlert />
      <InfoAlert />
      <ErrorAlert />
      <Modal
        show={visibility}
        onHide={onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton id="contained-modal-title-vcenter">
          {translations.chooseGroup}
        </Modal.Header>
        <Modal.Body>
          <GroupSelectFormModalBody
            extraOptions={[
              {
                id: courseAndGroupSelectFormModalBodyConstants.add,
                name: courseAndGroupSelectFormModalBodyConstants.newGroup,
              },
            ]}
            labelText={translations.addToGroup}
            showInfoText={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {translations.cancel}
          </Button>
          <Button
            variant="primary"
            onClick={handleChooseGroup}
            disabled={(group === '' || group === 'add') && newGroup === ''}
          >
            {translations.add}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddToGroupModal;
