import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import translations from '../../translations/forgotPassword';
import validateEmail from '../../utils/validateEmail';
import { http } from '../../services/http';
import { forgotPasswordURL } from '../../utils/urls';
import constants from '../../constants/account';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(true);
  const location = useLocation();

  const handleChange = event => {
    const { value } = event.target;
    setEmail(value);
    setError('');
  };

  const handleBlur = event => {
    const { value } = event.target;
    const newError = validateEmail(value) ? '' : translations.invalidEmail;
    setError(newError);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { client, resetPasswordlUrl } = constants;

    const newError = validateEmail(email) ? '' : translations.invalidEmail;
    setError(newError);
    if (!newError) {
      try {
        await http.post(forgotPasswordURL(), {
          email,
          client,
          resetPasswordLink: `${window.location.host}${resetPasswordlUrl}`,
        });
        setShowForm(false);
      } catch (e) {
        const { data } = e.response;
        setError(data);
      }
    }
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="overflow-hidden border-2 my-5">
            <Card.Body className="p-0">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div className="p-5">
                    <Card.Title className="text-center">
                      {translations.forgotPassword}
                    </Card.Title>
                    {showForm ? (
                      <Form
                        className="mt-5 mb-5"
                        onSubmit={handleSubmit}
                        noValidate
                      >
                        <Form.Group controlId="email">
                          <Form.Control
                            isInvalid={email && !!error}
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-left"
                            data-testid="email-feedback"
                          >
                            {error}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                          block
                          variant="primary"
                          type="submit"
                          disabled={email === ''}
                        >
                          {translations.sendRequest}
                        </Button>
                      </Form>
                    ) : (
                      <Alert variant="success" className="mt-5 mb-5">
                        <p>{translations.successMessage}</p>
                      </Alert>
                    )}
                    {location.state && location.state.prevPath === '/login' && (
                      <div className="text-center mb-2">
                        <Link to="/login">{translations.backToLogin}</Link>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
