import React from 'react';
import { Container, Row, Form, Badge, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import translations from '../../../../../translations/createUsersModal';

const CreatorKeyModalBody = ({ creatorKey, generateNewCode }) => {
  const handleChange = () => {
    const acceptKeyCheckbox = document.getElementById('acceptKeyCheckbox');
    const acceptKeyCheckboxContainer = document.getElementById(
      'acceptKeyCheckboxContainer'
    );
    if (acceptKeyCheckbox.checked) {
      acceptKeyCheckboxContainer.classList.remove('accept-warning');
    }
  };

  return (
    <>
      <p>{translations.teacherCodeInfo}</p>
      <Container>
        <Row className="centered">
          <h1>
            <Badge className="bg-secondary text-white text-uppercase">
              {creatorKey}
            </Badge>
          </h1>
        </Row>
        <Row className="centered underline" onClick={generateNewCode}>
          <Nav.Item>
            <Nav.Link href="" className="text-black">
              <FontAwesomeIcon className=" mr-2" icon={faRedoAlt} />
              {translations.generateNewCode}
            </Nav.Link>
          </Nav.Item>
        </Row>
        <Form.Row id="acceptKeyCheckboxContainer">
          <Form.Check
            id="acceptKeyCheckbox"
            className="pl-4 mb-3"
            type="checkbox"
            onChange={handleChange}
            label={translations.acceptKeyMsg}
          />
        </Form.Row>
      </Container>
    </>
  );
};

export default CreatorKeyModalBody;
