import React, { useState, useEffect } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { hideInfoAlert } from './duck';

const InfoAlert = () => {
  const infoAlert = useSelector(state => state.infoAlert);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(infoAlert ? infoAlert.title : '');
    setMessage(infoAlert ? infoAlert.message : '');
  }, [infoAlert]);

  const handleHide = () => {
    dispatch(hideInfoAlert());
  };

  return (
    <Modal
      show={!!title}
      onHide={handleHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton id="contained-modal-title-vcenter">
        <h5 className="font-weight-bold">{title}</h5>
      </Modal.Header>
      <Modal.Body>
        <Alert show variant="secondary">
          <p>{message}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={handleHide} variant="outline-secondary">
              OK
            </Button>
          </div>
        </Alert>
      </Modal.Body>
    </Modal>
  );
};

export default InfoAlert;
