import React, { useEffect, useState } from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import './index.css';
import translations from '../../../translations/groupsDropdown';
import { createGroup, fetchGroups } from './duck';
import GroupNameModal from '../GroupNameModal';

const GroupsDropdown = () => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const groups = useSelector(state => state.groups);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  const handleCreateGroup = event => {
    event.preventDefault();
    event.stopPropagation();
    setModalShow(true);
  };

  const handleSaveGroup = name => {
    setModalShow(false);
    if (name.trim().length) {
      dispatch(createGroup(name));
    }
  };

  const handleHideModal = () => {
    setModalShow(false);
  };

  const handleSelectGroup = id => {
    history.push(`/groups/${id}`);
  };

  return (
    <>
      <Dropdown as={NavItem} show>
        <Dropdown.Toggle
          as={NavLink}
          className="groups-dropdown-toggle"
          data-testid="dropdown-toggle"
        >
          <FontAwesomeIcon icon={faUsers} className="mr-2" />
          <span>{translations.groups}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            className="groups-dropdown-item"
            onClick={handleCreateGroup}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            <span>{translations.createNewGroup}</span>
          </Dropdown.Item>
          <Dropdown.Header className="groups-dropdown-header">
            <span>{translations.myGroups}</span>
          </Dropdown.Header>
          <div className="groups-dropdown-items">
            {groups.map(group => {
              return (
                <Dropdown.Item
                  key={group.id}
                  eventKey={group.id}
                  onSelect={handleSelectGroup}
                  className="groups-dropdown-item"
                >
                  {group.name}
                </Dropdown.Item>
              );
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {modalShow ? (
        <GroupNameModal
          show={modalShow}
          onHide={handleHideModal}
          onSave={handleSaveGroup}
        />
      ) : null}
    </>
  );
};

export default GroupsDropdown;
