import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { hideError } from './duck';
import translations from '../../translations/errorNotification';

const ErrorNotification = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const error = useSelector(state => state.error);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (error && error.response) {
      if (error.response.status === 403) {
        localStorage.removeItem('user');
        localStorage.removeItem('courseTree');
        history.push('/login');
        setErrorMessage('');
      } else if (error.response.message) {
        setErrorMessage(error.response.message);
      } else {
        setErrorMessage(translations.errorHasOccurred);
      }
    } else {
      setErrorMessage('');
    }
  }, [error, errorMessage, history]);

  const handleHide = () => {
    dispatch(hideError());
  };
  return (
    <>
      <Modal
        show={!!errorMessage}
        onHide={handleHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {error && error.title ? error.title : translations.error}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea className="form-control" rows="6">
            {errorMessage}
          </textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            {translations.ok}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ErrorNotification;
