import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import '../index.css';
import LessonsList from '../LessonsList';

const LessonsListTooltip = ({ children, lessons, placement, courseName }) => {
  return (
    <>
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip
            id={`tooltip-${placement}`}
            data-testid={`tooltip-${placement}`}
            className="custom-tooltip"
          >
            <LessonsList lessons={lessons} courseName={courseName} />
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    </>
  );
};

export default LessonsListTooltip;
