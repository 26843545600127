import { combineReducers } from 'redux';

import groups from '../components/Home/GroupsDropdown/duck';
import groupContent from '../components/Home/GroupContent/duck';
import loadingSpinnerReducer from '../components/Home/LoadingSpinner/duck';
import notysingUsers from '../components/Home/UserTimeline/duck';
import usersId from '../components/Home/Account/duck';
import error from '../components/ErrorNotification/duck';
import successAlert from '../components/SuccessAlert/duck';
import infoAlert from '../components/InfoAlert/duck';
import errorAlert from '../components/ErrorAlert/duck';
import googleClassroomReducer from '../components/Home/HomeScreen/ImportUsers/GoogleClassroomCallback/duck';
import courseAndGroupSelectReducer from '../components/Home/HomeScreen/ImportUsers/CourseAndGroupSelectFormModalBody/duck';

const appReducer = combineReducers({
  groups,
  groupContent,
  loadingSpinnerReducer,
  notysingUsers,
  usersId,
  error,
  googleClassroomReducer,
  successAlert,
  infoAlert,
  errorAlert,
  courseAndGroupSelectReducer,
});

export const CLEAN_UP_STORE = 'CLEAN_UP_STORE';

export const cleanUpStore = () => {
  return {
    type: CLEAN_UP_STORE,
  };
};

const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_UP_STORE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
