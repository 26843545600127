import { http } from '../../../services/http';
import { getGroupURL } from '../../../utils/urls';
import { setError } from '../../ErrorNotification/duck';

/*
 * action types
 */

export const SET_USERS_ID = 'SET_USERS_ID';

/*
 * action creators
 */

export const setUserIds = groupUsersId => {
  return {
    type: SET_USERS_ID,
    groupUsersId,
  };
};

export const getUsersfromGroup = id => {
  return async dispatch => {
    try {
      const { data } = await http.get(getGroupURL(id));
      const groupUsersId = data.users.map(user => user.id);

      dispatch(setUserIds(groupUsersId));
    } catch (e) {
      dispatch(setError(e));
    }
  };
};

/*
 * Reducers
 */

const initialState = [];

const usersId = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_ID:
      return [...new Set([...state, ...action.groupUsersId])];
    default:
      return state;
  }
};

export default usersId;
