import { RESOURCES, API } from '../serverConfig';

export const loginURL = () => '/auth/login/email';
export const createAccountURL = () => '/user/email';
export const logoutURL = () => '/logout';
export const createGroupURL = () => '/group';
export const updateGroupURL = id => `/group/${id}`;
export const deleteGroupURL = id => `/group/${id}`;
export const deleteUserURL = (gId, uId) => `/group/${gId}/user/${uId}`;
export const fetchGroupsURL = () => '/current-user/groups';
export const getGroupURL = id => `/group/${id}`;
export const forgotPasswordURL = () => `/forgot-password`;
export const updatePasswordURL = () => `/update-password`;
export const setPasswordURL = () => `/set-password`;
export const getCurrentUserURL = () => '/current-user';
export const getGoogleClassroomCoursesURL = cb =>
  `${API}/google-classroom/courses?callback=${cb}`;
export const getGoogleClassroomCourseStudentsURL = (cId, cb) =>
  `${API}/google-classroom/courses/${cId}/students?callback=${cb}`;
export const addUsersToGroupURL = id => `/group/${id}/users`;
export const googleLoginURL = cb => `${API}/auth/login/google?callback=${cb}`;

export const getResourcesRootURL = () => RESOURCES;
export const getCourseTreeURL = () => `resources/files/treeSingNotation.json`;
export const requestVerificationEmailURL = () => `/request-verification-email`;
export const verifyEmailURL = token => `/verify-email?token=${token}`;
export const connectEmailURL = () => `/auth/connect/email`;
export const updateEmailURL = () => `/update-email`;
export const googleConnectURL = cb =>
  `${API}/auth/connect/google?callback=${cb}`;
export const googleDisconnectURL = () => '/disconnect/google';
export const deleteAccountURL = () => '/delete/account';
export const getHostedPageURL = (type, redirectUrl) =>
  `/get-chargebee-hosted-page?type=${type}&redirect_url=${redirectUrl}`;
export const createPortalSessionURL = () => '/chargebee-portal-session/create';
export const currentUserSyncPurchasesURL = () => '/current-user/sync-purchases';
export const cancelSubscriptionURL = () => '/cancel-subscription';
export const getCreatorKeysURL = () => '/get-creator-keys';
export const setCreatorKeyURL = () => '/set-creator-key';
export const userNamesURL = () => '/users/username';
export const updateUserPasswordsURL = () => '/update-username-users-passwords';
