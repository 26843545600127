import React from 'react';
import { OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap';

import '../index.css';

const CourseIconTooltip = ({ children, placement, courseName }) => {
  return (
    <>
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip
            id={`tooltip-${placement}`}
            data-testid={`tooltip-${placement}`}
            className="custom-tooltip"
          >
            <ListGroup className="lessons-list-group">
              <ListGroup.Item
                variant="secondary"
                key={courseName}
                className="lessons-list-group-item bold"
              >
                {courseName}
              </ListGroup.Item>
            </ListGroup>
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    </>
  );
};

export default CourseIconTooltip;
