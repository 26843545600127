import flattenSingleLevelArray from './flattenSingleLevelArray';

const reshapeCourseTree = courseTree => {
  const coursesPerLevel = Object.values(courseTree.levels)
    .map(level => level.courses)
    .map(course => Object.values(course));

  const courses = flattenSingleLevelArray(coursesPerLevel);

  const getExercises = exercisesArray =>
    exercisesArray.map(exercise => ({
      id: exercise.id,
      type: exercise.type,
    }));

  const lessons = courses
    .map(course => course.lessons)
    .map(courseWithLessons => Object.values(courseWithLessons))
    .map(lessonsArray =>
      lessonsArray.map(lessonObj => ({
        id: lessonObj.id,
        name: lessonObj.name,
        exercises: lessonObj.exercises
          ? getExercises(Object.values(lessonObj.exercises))
          : [],
      }))
    );

  return courses.map((course, i) => ({
    ...course,
    lessons: lessons[i],
  }));
};

export default reshapeCourseTree;
