/*
 * action types
 */

export const SET_ERROR = 'SET_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

/*
 * action creators
 */

export const setError = error => {
  return {
    type: SET_ERROR,
    error,
  };
};

export const hideError = () => {
  return {
    type: HIDE_ERROR,
  };
};

/*
 * Reducer
 */

const initialState = null;

const errorReducer = (state = initialState, action) => {
  const { error } = action;

  if (error) {
    return error;
  }
  if (action.type === HIDE_ERROR) {
    return null;
  }

  return state;
};

export default errorReducer;
