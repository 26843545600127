/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { useCourseTree } from '../../../hooks/useCourseTree';
import isCourseTreeEmpty from '../../../utils/isCourseTreeEmpty';
import { getScoreAllExercises } from '../../../utils/reshapeUserWithExercises';
import LoadingSpinner from '../LoadingSpinner';
import { getGroup } from '../GroupContent/duck';
import './index.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const UserTimeline = ({ id, groupId }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);
  const [userScoreAllExercises, setUserScoreAllExercises] = useState([]);
  const usersWithExercises = useSelector(
    state => state.groupContent.usersWithExercises
  );
  const courseTree = useCourseTree();

  useEffect(() => {
    if (usersWithExercises.length && !isCourseTreeEmpty(courseTree)) {
      setUserScoreAllExercises(
        getScoreAllExercises(
          usersWithExercises.find(notysingUser => notysingUser.id === id),
          courseTree
        )
      );
      setUser(usersWithExercises.find(notysingUser => notysingUser.id === id));
    }
  }, [courseTree, usersWithExercises]);

  useEffect(() => {
    if (!usersWithExercises.length && !isCourseTreeEmpty(courseTree)) {
      dispatch(getGroup(groupId, courseTree));
    }
  }, [usersWithExercises, courseTree]);

  return (
    <div className="ml-3 d-flex flex-column">
      {user && <div className="m-3">{user.alias}</div>}
      <LoadingSpinner />
      <BootstrapTable data={userScoreAllExercises} striped hover>
        <TableHeaderColumn isKey dataField="key">
          Key
        </TableHeaderColumn>
        <TableHeaderColumn dataField="finished">finished</TableHeaderColumn>
        <TableHeaderColumn dataField="lesson">LESSON</TableHeaderColumn>
        <TableHeaderColumn dataField="exercise">EXERCISE</TableHeaderColumn>
        <TableHeaderColumn dataField="type">TYPE</TableHeaderColumn>
        <TableHeaderColumn dataField="score">SCORE</TableHeaderColumn>
        <TableHeaderColumn dataField="maxScore">MAXSCORE</TableHeaderColumn>
        <TableHeaderColumn dataField="timestamp">TIME</TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default UserTimeline;
