const translations = {
  login: 'LOGIN',
  invalidEmailOrPassword: 'Email you entered might be incorrect.',
  invalidPasswordOrEmail: 'Password you entered might be incorrect.',
  newUser: 'New user?',
  createNewAccount: 'Create new account.',
  forgotPassword: 'Forgot your password?',
};

export default translations;
