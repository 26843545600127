import React from 'react';
import { Form } from 'react-bootstrap';

const CurrentPassword = ({
  handleChange,
  handleBlur,
  currentPassword,
  errors,
}) => {
  return (
    <Form.Group controlId="currentPassword">
      <Form.Control
        isInvalid={currentPassword && !!errors.currentPassword}
        type="password"
        placeholder="Current password"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Form.Control.Feedback type="invalid" className="text-left">
        {errors.currentPassword}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default CurrentPassword;
