import React, { useState } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';

import translations from '../../../translations/resetPassword';
import { http } from '../../../services/http';
import validateForm from '../../../utils/validateForm';
import { updatePasswordURL } from '../../../utils/urls';
import BackToPage from '../BackToPage';
import ResetPasswordForm from '../ResetPasswordForm';
import CurrentPassword from '../ResetPasswordForm/CurrentPassword';
import NewPassword from '../ResetPasswordForm/NewPassword';
import VerifyPassword from '../ResetPasswordForm/VerifyPassword';

const ResetPasswordFromAccount = () => {
  const [values, setValues] = useState({
    currentPassword: '',
    password: '',
    verifyPassword: '',
  });
  const { currentPassword, password, verifyPassword } = values;
  const [errors, setErrors] = useState({
    currentPassword: '',
    password: '',
    verifyPassword: '',
  });
  const [showForm, setShowForm] = useState(true);

  const handleChange = event => {
    event.preventDefault();
    const { id, value } = event.target;
    setValues(prevValues => ({ ...prevValues, [id]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
  };

  const handleBlur = event => {
    const { id } = event.target;
    if (id === 'password') {
      const newErrors = validateForm(values);

      setErrors(prevErrors => ({
        ...prevErrors,
        password: newErrors.password,
      }));
    }

    if (id === 'verifyPassword') {
      const newErrors = validateForm(values);
      setErrors(prevErrors => ({
        ...prevErrors,
        verifyPassword: newErrors.verifyPassword,
      }));
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!Object.values(validateForm(values)).length) {
      try {
        await http.post(updatePasswordURL(), {
          currentPassword,
          newPassword: password,
        });
        setShowForm(false);
      } catch (e) {
        const { data } = e.response;
        setErrors(prevErrors => ({
          ...prevErrors,
          currentPassword: data,
        }));
      }
    } else {
      setErrors(validateForm(values));
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="overflow-hidden border-2 my-5">
            <Card.Body className="p-0">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div className="p-5">
                    <Card.Title className="text-center">
                      {translations.resetPassword}
                    </Card.Title>
                    {showForm ? (
                      <ResetPasswordForm
                        handleSubmit={handleSubmit}
                        values={values}
                      >
                        <CurrentPassword
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          currentPassword={currentPassword}
                          errors={errors}
                        />
                        <NewPassword
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          password={password}
                          errors={errors}
                        />
                        <VerifyPassword
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          verifyPassword={verifyPassword}
                          errors={errors}
                        />
                      </ResetPasswordForm>
                    ) : (
                      <Alert variant="success" className="mt-5 mb-5">
                        {translations.passwordHasBeenReset}
                      </Alert>
                    )}
                    <BackToPage
                      to="/account"
                      text={translations.backToAccount}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordFromAccount;
