import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import './index.css';
import { Container, Row, Spinner } from 'react-bootstrap';
import { setError } from '../ErrorNotification/duck';
import b64DecodeUnicode from '../../utils/b64DecodeUnicode';

const Callback = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { search } = location;
    if (search) {
      const callbackData = b64DecodeUnicode(
        new URLSearchParams(search).get('data')
      );
      const { user, status } = JSON.parse(callbackData);
      if (status && status >= 300) {
        const error = new Error();
        error.response = {
          status,
        };
        dispatch(setError(error));
        history.push('/login');
      } else {
        localStorage.setItem('user', JSON.stringify(user));
        history.push('/');
      }
    }
  }, [dispatch, history, location]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Spinner animation="grow" variant="success" />
        <Spinner animation="grow" variant="success" />
        <Spinner animation="grow" variant="success" />
      </Row>
    </Container>
  );
};

export default Callback;
