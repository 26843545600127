import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const SortTh = ({ label, sortKey, sortState, dispatch }) => {
  const toggleDirectionAndSetKey = () => {
    dispatch({ key: sortKey });
    dispatch({ isDescending: !sortState.isDescending });
  };

  return (
    <th
      className="student-table-head-cell student-table-cell"
      onClick={toggleDirectionAndSetKey}
      role="button"
    >
      {label}
      {sortState.key === sortKey && (
        <FontAwesomeIcon
          className={`ml-2 ${
            sortState.isDescending ? 'sort-down-middle' : 'sort-up-middle'
          }`}
          icon={sortState.isDescending ? faSortDown : faSortUp}
        />
      )}
    </th>
  );
};

export default SortTh;
