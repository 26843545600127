const constants = {
  connectGoogleCallbackRoute: '/account',
  pricePerUser: 2.99,
  minUsers: 30,
  client: 'notysing',
  verifyEmailUrl: '/verify-email',
  resetPasswordlUrl: '/reset-password',
  chargeeBeeCheckoutRedirectUrl: 'https://notysing.com/payment-complete/',
  chargeeBeePortalRedirectUrl: '/account',
};

export default constants;
