/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import { getResourcesRootURL, deleteUserURL } from '../../../utils/urls';
import GroupTableElement from '../GroupTableElement';
import translations from '../../../translations/groupTable';
import { http } from '../../../services/http';
import { getGroup } from '../GroupContent/duck';
import getRowUserId from '../../../utils/getRowUserId';
import { setError } from '../../ErrorNotification/duck';
import ConfirmationModal from '../ConfirmationModal';
import isCourseTreeEmpty from '../../../utils/isCourseTreeEmpty';
import CourseIconTooltip from '../CourseIconTooltip';

const GroupTable = ({ gId, courses }) => {
  const isLoadingSpinnerVisible = useSelector(
    state => state.loadingSpinnerReducer.isVisible
  );

  const history = useHistory();
  const [showIconId, setShowIconId] = useState('');
  const [deletingId, setDeletingId] = useState('');
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const users = useSelector(state => state.groupContent.users);
  const dispatch = useDispatch();

  const handleDeleteUser = () => {
    setConfirmationModalShow(true);
    setDeletingId(showIconId);
  };

  const handleConfirmDelete = async () => {
    try {
      await http.delete(deleteUserURL(gId, deletingId));
      setDeletingId('');
      setConfirmationModalShow(false);
      dispatch(getGroup(gId, courses));
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const handleCancelDelete = () => {
    setConfirmationModalShow(false);
    setDeletingId('');
  };

  const handleMouseEnter = event => {
    event.persist();
    const { id } = event.target;
    setShowIconId(getRowUserId(id));
  };

  const handleMouseLeave = event => {
    event.persist();
    setShowIconId('');
  };

  const handleUserClick = event => {
    const { id } = event.target;
    const studenId = id.split('-').pop();
    history.push(`/user-timeline/${gId}/${studenId}/${users[studenId].alias}`);
  };

  const sortedUsers = Object.keys(users).sort((a, b) =>
    users[a].alias > users[b].alias ? 1 : -1
  );

  return (
    <>
      {Object.keys(users).length ? (
        <div className={"group-table-wrapper " + (isLoadingSpinnerVisible ? 'loading' : '')}>
          <table className="group-table">
            <thead>
              <tr className="group-table-head-row">
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="group-table-head-cell" />
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="group-table-first-col group-table-head-cell" />
                {!isCourseTreeEmpty(courses)
                  ? courses.map(course => (
                      <th key={course.id} className="group-table-head-cell">
                        <CourseIconTooltip
                          courseName={course.name}
                          placement="top"
                        >
                          <img
                            className="group-table-icon"
                            alt={course.name}
                            src={`${getResourcesRootURL()}/${course.icon}`}
                          />
                        </CourseIconTooltip>
                      </th>
                    ))
                  : null}
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map(userId => (
                <tr key={userId} className="group-table-body-row">
                  <td
                    className="group-table-body-delete-icon-cell align-middle"
                    id={`delete-icon-cell-${userId}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {showIconId.endsWith(userId) ? (
                      <FontAwesomeIcon
                        data-testid={`delete-icon-${userId}`}
                        id={`delete-icon-${userId}`}
                        icon={faTrashAlt}
                        onClick={handleDeleteUser}
                      />
                    ) : (
                      <div className="group-table-empty-div" />
                    )}
                  </td>
                  <td
                    data-testid={`user-alias-cell-${userId}`}
                    id={`user-alias-cell-${userId}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleUserClick}
                    className="group-table-body-first-cell align-middle"
                  >
                    {users[userId].alias}
                  </td>
                  {!isCourseTreeEmpty(courses)
                    ? courses.map(course => (
                        <td
                          key={course.id}
                          id={userId}
                          className="group-table-body-cell align-middle"
                        >
                          <GroupTableElement
                            allCourses={courses}
                            courseName={course.name}
                            course={users[userId].courses[course.id]}
                          />
                        </td>
                      ))
                    : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Row>
          <p className="ml-4">{translations.addAUser}</p>
        </Row>
      )}
      {confirmationModalShow ? (
        <ConfirmationModal
          show={confirmationModalShow}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
    </>
  );
};

export default GroupTable;
