const trimDoubleSpaces = str => str.replace(/\s{2,}/g, ' ').trim();
const trimDoubleQuotes = str => str.replace(/['"]+/g, '');

const getArrayFromList = usersString => {
  const users = usersString.split('\n');
  return users.map(userStr => {
    const trimedUser = trimDoubleQuotes(trimDoubleSpaces(userStr));
    let user = [];
    if (trimedUser.includes('\t')) {
      user = trimedUser.split('\t', 2);
    } else if (trimedUser.includes(';')) {
      user = trimedUser.split(';', 2);
    } else if (trimedUser.includes(',')) {
      user = trimedUser.split(',', 2);
    } else if (trimedUser.includes(' ')) {
      user = trimedUser.split(' ', 2);
    } else {
      user.push(userStr);
    }
    return user;
  });
};

export default getArrayFromList;
