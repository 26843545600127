import { useDispatch } from 'react-redux';
import { setSuccessAlert } from '../components/SuccessAlert/duck';
import { setInfoAlert } from '../components/InfoAlert/duck';
import { setErrorAlert } from '../components/ErrorAlert/duck';

// eslint-disable-next-line import/prefer-default-export
export const useAlert = () => {
  const dispatch = useDispatch();

  const showInfoAlert = (title, message) => {
    dispatch(
      setInfoAlert({
        title,
        message,
      })
    );
  };

  const showSuccessAlert = (title, message) => {
    dispatch(
      setSuccessAlert({
        title,
        message,
      })
    );
  };

  const showErrorAlert = (title, message) => {
    dispatch(
      setErrorAlert({
        title,
        message,
      })
    );
  };

  return {
    info: showInfoAlert,
    success: showSuccessAlert,
    error: showErrorAlert,
  };
};
