import { http } from '../../../services/http';
import { getGroupURL } from '../../../utils/urls';
import reshapeUsers from '../../../utils/reshapeUsers';
import { getNotysingUsers } from '../../../utils/reshapeUserWithExercises';
import { setError } from '../../ErrorNotification/duck';

/*
 * action types
 */

export const SET_USERS = 'SET_USERS';
export const SET_NOTYSING_USERS = 'SET_NOTYSING_USERS';

/*
 * action creators
 */

export const setUsers = (groupUsers, usersWithExercises) => {
  return {
    type: SET_USERS,
    groupUsers,
    usersWithExercises,
  };
};

export const getGroup = (id, courseTree) => {
  return async dispatch => {
    try {
      const { data } = await http.get(getGroupURL(id));
      const users = reshapeUsers(data, courseTree);
      const usersWithExercises = getNotysingUsers(data);
      dispatch(setUsers(users, usersWithExercises));
    } catch (e) {
      dispatch(setError(e));
    }
  };
};

/*
 * Reducers
 */

const initialState = {
  users: [],
  usersWithExercises: [],
};

const groupContent = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.groupUsers,
        usersWithExercises: action.usersWithExercises,
      };
    default:
      return state;
  }
};

export default groupContent;
