import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import translations from '../../../translations/confirmationModal';

const ConfirmationModal = ({ show, onCancel, onConfirm }) => {
  const handleCancelClick = () => {
    onCancel();
  };

  const handleConfirmClick = () => {
    onConfirm();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCancelClick}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {translations.areYouSure}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>
            {translations.cancel}
          </Button>
          <Button variant="primary" onClick={handleConfirmClick}>
            {translations.ok}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
