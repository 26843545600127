import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import constants from '../../../../../constants/importUsersModal';
import { setCourses, setStudents, setStep } from './duck';
import ErrorNotification from '../../../../ErrorNotification';
import Sidebar from '../../../Sidebar';
import Content from '../../index';
import { setError } from '../../../../ErrorNotification/duck';
import b64DecodeUnicode from '../../../../../utils/b64DecodeUnicode';
import { httpRes } from '../../../../../services/http';

const GoogleClassroomCallback = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const step = useSelector(state => state.googleClassroomReducer.step);

  useEffect(() => {
    const { search, pathname } = location;

    const getCourses = async url => {
      try {
        const { data } = await httpRes.get(url);
        const { courses = [] } = data;
        dispatch(setCourses(courses));
        dispatch(setStep(2));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const getStudents = async url => {
      try {
        const { data } = await httpRes.get(url);
        const { students = [] } = data;
        dispatch(
          setStudents(
            students.map(({ profile }) => {
              return {
                ...profile,
                name: profile.name.fullName,
                photoUrl: '',
              };
            })
          )
        );
        dispatch(setStep(3));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    if (pathname === constants.googleClassroomCoursesCallbackRoute) {
      try {
        const callbackData = b64DecodeUnicode(
          new URLSearchParams(search).get('data')
        );
        const { url, status } = JSON.parse(callbackData);
        if (status >= 300) {
          const error = new Error();
          error.response = {
            status,
          };
          dispatch(setError(error));
          history.push('/');
        } else {
          getCourses(url);
        }
      } catch (e) {
        history.push('/');
      }
    } else if (
      pathname.startsWith(constants.googleClassroomCourseStudentsCallbackRoute)
    ) {
      try {
        const callbackData = b64DecodeUnicode(
          new URLSearchParams(search).get('data')
        );
        const { url, status } = JSON.parse(callbackData);
        if (status >= 300) {
          const error = new Error();
          error.response = {
            status,
          };
          dispatch(setError(error));
          history.push('/');
        } else {
          getStudents(url);
        }
      } catch (e) {
        history.push('/');
      }
    }
  }, [history, location, dispatch]);

  if (!step) {
    return (
      <>
        <ErrorNotification />
        <Container>
          <Row className="justify-content-center">
            <Spinner
              animation="grow"
              variant="success"
              data-testid="spinner-1"
            />
            <Spinner
              animation="grow"
              variant="success"
              data-testid="spinner-2"
            />
            <Spinner
              animation="grow"
              variant="success"
              data-testid="spinner-3"
            />
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <ErrorNotification />
      <div className="home-wrapper">
        <Sidebar />
        <Content />
      </div>
    </>
  );
};

export default GoogleClassroomCallback;
