const translations = {
  account: 'Account',
  changeEmail: 'Change Email',
  changeEmailDescription: 'Change your email and click save.',
  addEmail: 'Add Email',
  addEmailDescription: 'Enter an email address and click save',
  emailLogin: 'Email Login',
  save: 'SAVE',
  addPassword: 'Add Password',
  resetPassword: 'Change Password',
  verifyEmail: 'Re-send Verification Email',
  yourEmail: 'your email address',
  addPasswordDescription: 'A link to set password will be sent to',
  resetPasswordDescription: 'Click to change your password.',
  verifyEmailDescription: 'A verification link will be sent to',
  connectSocialLogins: 'Connect Social Logins',
  googleLoginTitle: 'Google Login',
  schoolFederationLoginTitle: 'School Federation Login',
  googleLoginLabel: 'Login using your Google Account',
  schoolFederationLoginLabel: 'Login using your School Federation Account',
  perYear: 'per year',
  users: 'users',
  upgradeAccount: 'Upgrade Account',
  subscribe: 'Start a subscription',
  alreadyUpgraded:
    "Your account is already upgraded (by being included in someone else's subscription or by a one-time purchase in the app). You can start a subscription of your own to upgrade the students in your groups.",
  upgradeAccountDescription: 'Unlock all features for you and your students.',
  actionRequired: 'Action Required',
  actionHelp: 'To upgrade you need a verified email.',
  actionHelpMessage:
    'Make sure you have added and verified your email address in your account settings. You can re-send verification link if needed.',
  readMore: ' Read more.',
  ok: 'OK',
  error: 'Error',
  errorMessageGeneral: 'Something went wrong. Please, try again later.',
  checkMail: 'Check your mail!',
  checkMailMessage: 'A link has been sent to',
  invalidEmail: 'Email is invalid.',
  emailSaved: 'Email has been successfully saved.',
  emptyEmail: 'Please, fill in email address.',
  success: 'Success',
  thankYou: 'Thank you for your purchase',
  cancelSubscription: 'Cancel subscription',
  reactivateSubscription: 'Reactivate subscription',
  schoolSubscription: 'School subscription',
  youHave: 'You have',
  inGroups: 'users in your groups.',
  googleIsDisconnected:
    'Google account has been successfully disconnected from your Dashboard account.',
  unableToDisconnectGoogle:
    'Unable to Disconnect your google account. Please, add another login option first.',
  deleteAccount: 'Delete Account',
  deleteAccountDescription: 'Permanently delete your account.',
  emailExists: 'User with this email already exists.',
  yourSubscription: 'Your Subscription',
  aboutSubscription: (existingAmount, numberOfSubscribedUsers) =>
    `You and the users in your groups are upgraded! There are ${existingAmount} users and ${numberOfSubscribedUsers} are upgraded. Add users to groups to upgrade them, remove users from groups to free up seats.`,
  aboutCancelledSubscription: periodEnd =>
    `Your subscription will be cancelled on ${periodEnd}. All lessons are available until then.`,
  reactivateSubscriptionDescription:
    'To reactivate the subscription, click  "Manage Subscription" and set the number of users to upgrade.',
  editSubscriptionFromPortal:
    'To change the number of upgraded user seats, click "Manage Subscription", click your subscription and then "Edit Subscription". Here you can also cancel your subscription.',
  editSubscriptionFromHostedPage:
    'To change the number of upgraded user seats, click "Manage Subscription".',
  downloadInvoices:
    'To view and download your invoices, click "Manage Subscription" and then to "Billing History". Here you can also change what payment method to use.',
  unlockAll: 'Unlock all lessons for you and for all students in your groups.',
  youHaveUserTotal: (userTotal, requiredAmount) =>
    `You have ${userTotal} users in your dashboard, including you. Upgrade at least ${requiredAmount} user seats to unlock the app for all those users.`,
  changeTheNumber:
    'When you remove a user from your groups, that seat becomes available for another user. You can change the number of upgraded seats whenever you want.',
  manageSubscription: 'Manage Subscription',
  reloadAndVerify:
    'Reload the page and make sure you have added and verified your email address in your account settings. You can re-send verification link if needed.',
  googleAlreadyConnected: 'Goggle account has already been connected.',
  googleInUse:
    'Your logged in Google account has already been connected to another account.',
  googleConnected: 'Google account has been successfully connected.',
};

export default translations;
