import React from 'react';
import { Form } from 'react-bootstrap';

const AccountCheckbox = ({ title, label, checked, onChange }) => {
  return (
    <>
      <Form.Row>
        <Form.Label column className="font-weight-bold">
          {title}
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Check
          className="pl-4 mb-3"
          type="checkbox"
          label={label}
          onChange={onChange}
          checked={checked}
        />
      </Form.Row>
    </>
  );
};

export default AccountCheckbox;
