import React from 'react';
import { Form } from 'react-bootstrap';

const NewPassword = ({ handleChange, handleBlur, password, errors }) => {
  return (
    <Form.Group controlId="password">
      <Form.Control
        isInvalid={password && !!errors.password}
        type="password"
        placeholder="New password"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Form.Control.Feedback type="invalid" className="text-left">
        {errors.password}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default NewPassword;
