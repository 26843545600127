/*
 * action types
 */

export const SET_SUCCESS_ALERT = 'SET_SUCCESS_ALERT';
export const HIDE_SUCCESS_ALERT = 'HIDE_SUCCESS_ALERT';

/*
 * action creators
 */

export const setSuccessAlert = successAlert => {
  return {
    type: SET_SUCCESS_ALERT,
    successAlert,
  };
};

export const hideSuccessAlert = () => {
  return {
    type: HIDE_SUCCESS_ALERT,
  };
};

/*
 * Reducer
 */

const initialState = null;

const successAlertReducer = (state = initialState, action) => {
  const { successAlert } = action;

  if (successAlert) {
    return successAlert;
  }
  if (action.type === HIDE_SUCCESS_ALERT) {
    return null;
  }

  return state;
};

export default successAlertReducer;
