import { http } from '../../../services/http';
import { getGroupURL } from '../../../utils/urls';
import { getNotysingUsers } from '../../../utils/reshapeUserWithExercises';
import { setError } from '../../ErrorNotification/duck';
import { setSpinnerVisibility } from '../LoadingSpinner/duck';

/*
 * action types
 */

export const SET_NOTYSING_USERS = 'SET_NOTYSING_USERS';

/*
 * action creators
 */

export const setNotysingUsers = notysingUsers => {
  return {
    type: SET_NOTYSING_USERS,
    notysingUsers,
  };
};

export const getNotysingUsersfromGroup = id => {
  return async dispatch => {
    try {
      dispatch(setSpinnerVisibility(true));
      const { data } = await http.get(getGroupURL(id));
      dispatch(setNotysingUsers(getNotysingUsers(data)));
      dispatch(setSpinnerVisibility(false));
    } catch (e) {
      dispatch(setError(e));
    }
  };
};

/*
 * Reducers
 */

const initialState = [];

const notysingUsers = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTYSING_USERS:
      return action.notysingUsers;
    default:
      return state;
  }
};

export default notysingUsers;
