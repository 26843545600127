import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import translations from '../../translations/createAccount';
import validateForm from '../../utils/validateForm';
import { http } from '../../services/http';
import { createAccountURL, getCurrentUserURL } from '../../utils/urls';

const CreateAccount = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const { email, password } = values;
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      history.push('/');
    }
  }, [history]);

  const handleChange = event => {
    const { id, value } = event.target;
    setValues(prevValues => ({ ...prevValues, [id]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
  };

  const handleBlur = event => {
    const { id } = event.target;
    if (id === 'email') {
      const newErrors = validateForm(values);
      setErrors(prevErrors => ({ ...prevErrors, email: newErrors.email }));
    } else {
      const newErrors = validateForm(values);
      setErrors(prevErrors => ({
        ...prevErrors,
        password: newErrors.password,
      }));
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!Object.values(validateForm(values)).length) {
      try {
        const { data: user } = await http.post(createAccountURL(), {
          email,
          password,
        });
        await http.get(getCurrentUserURL());
        localStorage.setItem('user', JSON.stringify(user));
        history.push('/');
      } catch (e) {
        if (e.response.status === 409) {
          setErrors(prevErrors => ({
            ...prevErrors,
            email: translations.emailExists,
          }));
        }
      }
    } else {
      setErrors(validateForm(values));
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="overflow-hidden border-2 my-5">
            <Card.Body className="p-0">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div className="p-5">
                    <Card.Title className="text-center">
                      {translations.createAccount}
                    </Card.Title>
                    <Form
                      className="mt-5 mb-5"
                      onSubmit={handleSubmit}
                      data-testid="form"
                      noValidate
                    >
                      <Form.Group controlId="email">
                        <Form.Control
                          isInvalid={email && !!errors.email}
                          type="email"
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="text-lowercase"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-left"
                          data-testid="email-feedback"
                        >
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="password">
                        <Form.Control
                          isInvalid={password && !!errors.password}
                          type="password"
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <Form.Control.Feedback
                          type="invalid"
                          className="text-left"
                          data-testid="password-feedback"
                        >
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        block
                        variant="primary"
                        type="submit"
                        disabled={Object.values(values).some(
                          value => value === ''
                        )}
                      >
                        {translations.createAccount}
                      </Button>
                    </Form>
                    <div className="text-center">
                      <span>{`${translations.oldUser} `}</span>
                      <Link to="/login">{translations.login}</Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateAccount;
